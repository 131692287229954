import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { Container } from "@material-ui/core";

import Header from "./Header";

import Amplify from "aws-amplify";
import config from "../aws-exports";
Amplify.configure({
  ...config,
  aws_appsync_authenticationType: "API_KEY",
});

const useStyles = makeStyles((theme) => ({}));

const Layout = ({ children, data }) => {
  const classes = useStyles();
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              keywords
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: "description", content: data.site.siteMetadata.title },
              { name: "keywords", content: data.site.siteMetadata.keywords },
            ]}
          >
            <html lang="en" />
          </Helmet>
          {children}
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#9a0b1a',
    },
    secondary: {
      main: '#01000f',
    }
  }
});

export default theme;


/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import { cloneDeep } from "lodash";
import { StateMachineProvider, createStore } from "little-state-machine";
import { Container } from "@material-ui/core";

import Layout from "./src/components/Layout";
import { blankState } from "./src/utils/kits";

import Auth from "@aws-amplify/auth";
import { setUser } from "./src/utils/auth";

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      const userInfo = {
        ...user.attributes,
        username: user.username,
      };
      setUser(userInfo);
    })
    .catch((err) => {
      window.localStorage.setItem("gatsbyUser", null);
    });
};

export const wrapRootElement = ({ element }) => {
  createStore(cloneDeep(blankState));
  return <StateMachineProvider>{element}</StateMachineProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

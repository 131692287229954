import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Link } from "gatsby-theme-material-ui";
import {
  makeStyles,
  AppBar,
  Button,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import { navigate } from "@reach/router";

import { logout, isLoggedIn } from "../utils/auth";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: "64px",
  },
}));

export default function Header({ siteTitle }) {
  const classes = useStyles();
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {false ? (
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
          ) : null}
          <Link to="/" className={classes.logo}>
            <StaticQuery
              query={graphql`
                query LogoQuery {
                  file(relativePath: { eq: "logo.png" }) {
                    childImageSharp {
                      fixed(height: 64) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              `}
              render={(data) => (
                <Img
                  fixed={data.file.childImageSharp.fixed}
                  alt={siteTitle}
                  title={siteTitle}
                />
              )}
            />
          </Link>
          {isLoggedIn() && (
            <Button
              color="inherit"
              onClick={() =>
                Auth.signOut()
                  .then(logout(() => navigate("/app/login")))
                  .catch((err) => console.log("eror:", err))
              }
            >
              Sign Out
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}

import { cloneDeep } from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import { checkAddress as checkAddressGQ } from "../graphql/queries";

/*
 * @param {object} $0
 * @param {string} $0.place_id Unique location ID from Google Places
 * @param {string} $0.description Description generated by Google Places
 * @returns {Promise<boolean>}
 */
export const checkAddress = async ({ place_id, description }) => {
  const results = await API.graphql(
    graphqlOperation(checkAddressGQ, {
      address: {
        placeId: place_id,
        description,
      },
    })
  );
  console.log(results);

  return (
    results.data.checkAddress.addressAvailable ||
    results.data.checkAddress.message
  );
};

export function updateLocalState(state, payload) {
  if (payload?.reset === true) {
    return cloneDeep(blankState);
  }
  return {
    ...state,
    data: {
      ...state.data,
      ...payload,
    },
  };
}

export const blankState = {
  data: {
    reset: false,
    recipient_name: "",
    recipient_address: {
      place_id: "",
      description: "",
      terms: [],
    },
    is_gift: false,
    gift_note: "",
    add_chocolate: false,
    background_image: "hardwood",
    sender_name: "",
  },
};

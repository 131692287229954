module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-manifest-virtual-4275453797/0/cache/gatsby-plugin-manifest-npm-2.7.0-bc78f11a92-ec0323d4e9.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HomeClix Kits","short_name":"HomeClix","start_url":"/","background_color":"#9a0b1a","theme_color":"#9a0b1a","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8d115da0a3d3a449b33382d37c04d9ac"},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-theme-material-ui-top-layout-virtual-052905d4b8/0/cache/gatsby-theme-material-ui-top-layout-npm-1.0.3-b086438656-2ae7773157.zip/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-material-ui-virtual-bd926eaa18/0/cache/gatsby-plugin-material-ui-npm-2.1.10-b8f9cb8bdc-32a12c93b5.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
